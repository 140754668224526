var render = function render(){
  var _vm$setup$info;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_vm.loading ? _c('div', [_c('Spinner')], 1) : _c('div', {
    staticClass: "mb-2"
  }, [_vm._m(0), _vm.requiredFields.length > 0 ? _c('div', {
    staticClass: "alert alert-warning mb-0"
  }, [_vm._m(1), _vm._l(_vm.requiredFields, function (item) {
    return _c('span', {
      key: item.key
    }, [_vm._v(" " + _vm._s(item.friendly) + _vm._s(_vm.requiredFields.indexOf(item) >= _vm.requiredFields.length - 1 ? '' : ', ') + " ")]);
  })], 2) : _vm._e(), _vm.mappingValues.length > 0 ? _c('table', {
    staticClass: "table table-borderless"
  }, [_vm._m(2), _c('tbody', _vm._l(_vm.mappingValues, function (item, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_c('div', [_vm._v(_vm._s(item.heading))])]), _c('td', [!item.ignoreMapping ? _c('select', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.value,
        expression: "item.value"
      }],
      staticClass: "form-control",
      on: {
        "change": function ($event) {
          var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
            return o.selected;
          }).map(function (o) {
            var val = "_value" in o ? o._value : o.value;
            return val;
          });
          _vm.$set(item, "value", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
        }
      }
    }, [_c('option', {
      attrs: {
        "value": ""
      }
    }), _vm._l(_vm.availableFields.filter(function (field) {
      return item.value === field.key || !_vm.mappingValues.some(function (h) {
        return h.value === field.key;
      }) || field.type === 'array';
    }), function (value) {
      return _c('option', {
        key: value.key,
        domProps: {
          "value": value.key
        }
      }, [_vm._v(" " + _vm._s(value.friendly) + " ")]);
    })], 2) : _c('div', {
      staticClass: "text-muted"
    }, [_vm._v("This heading does not require a mapping")])])]);
  }), 0)]) : _c('div', {
    staticClass: "row justify-content-between"
  }, [_vm._m(3), _vm._m(4)]), _c('div', {
    staticClass: "d-flex justify-content-between mb-4"
  }, [_c('router-link', {
    staticClass: "btn btn-light",
    attrs: {
      "to": {
        name: 'import-upload',
        query: this.$route.query,
        params: this.$route.params
      }
    }
  }, [_vm._v("Back")]), _c('button', {
    staticClass: "btn btn-primary",
    class: {
      disabled: _vm.requiredFields.length > 0
    },
    on: {
      "click": _vm.onSubmit
    }
  }, [_vm._v("Submit")])], 1), _c('div', {
    staticClass: "info-box mb-4"
  }, [_vm._m(5), _c('p', {
    staticClass: "mb-2"
  }, [_vm._v("Below is an explanation of what each field is that you can map to, and anything else that should be considered.")]), _c('p', {
    staticClass: "mb-2"
  }, [_vm._v(" For fields where there is a relation (for example, Asset's have a related Entity), the relation column will show what you should enter. ")]), Object.values(((_vm$setup$info = _vm.setup.info) === null || _vm$setup$info === void 0 ? void 0 : _vm$setup$info.fields) || []).length > 0 ? _c('table', {
    staticClass: "table"
  }, [_vm._m(6), _c('tbody', _vm._l(Object.values(_vm.setup.info.fields), function (field, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_c('div', {
      staticClass: "font-w600"
    }, [_vm._v(_vm._s(field.friendly))]), _c('div', {
      staticClass: "pr-3"
    }, [_c('small', [_vm._v(_vm._s(field.description))])])]), _c('td', [field.required ? _c('span', {
      staticClass: "font-w600"
    }, [_c('i', {
      staticClass: "fa fa-circle-check mr-1 text-success"
    }), _vm._v(" Yes")]) : _vm._e()]), _c('td', [_vm._v(_vm._s(field.relations ? field.relations.join(', ') : ''))])]);
  }), 0)]) : _vm._e()]), _c('div', {
    staticClass: "d-flex justify-content-between mb-4"
  }, [_c('router-link', {
    staticClass: "btn btn-light",
    attrs: {
      "to": {
        name: 'import-upload',
        query: this.$route.query,
        params: this.$route.params
      }
    }
  }, [_vm._v("Back")]), _c('button', {
    staticClass: "btn btn-primary",
    class: {
      disabled: _vm.requiredFields.length > 0
    },
    on: {
      "click": _vm.onSubmit
    }
  }, [_vm._v("Submit")])], 1)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-items-center alert alert-info mb-3"
  }, [_c('i', {
    staticClass: "fa fa-circle-info fa-xl mr-4"
  }), _c('div', [_c('p', {
    staticClass: "mb-1"
  }, [_c('strong', [_vm._v("Map the headings from your file with their relevant fields in Etainabl.")]), _vm._v(" We have attempted to guess some, but you may need to fill the rest. ")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v("Scroll down for further details on each Etainabl field. If you need further help, please contact Etainabl Support.")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('strong', [_c('i', {
    staticClass: "fa fa-exclamation-triangle mr-1"
  }), _vm._v("The following fields require a mapping:")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('th', [_vm._v("Heading (From uploaded file)")]), _c('th', [_vm._v("Map To")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col"
  }, [_c('h4', {
    staticClass: "text-success mb-2"
  }, [_c('i', {
    staticClass: "fad fa-check mr-1"
  }), _vm._v(" All fields selected")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col text-right"
  }, [_c('button', {
    staticClass: "btn btn-success"
  }, [_vm._v("Preview")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-items-center mb-2"
  }, [_c('i', {
    staticClass: "fa fa-circle-question fa-xl mr-2"
  }), _c('h3', {
    staticClass: "mb-0"
  }, [_vm._v("Help")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('th', {
    staticStyle: {
      "width": "66%"
    }
  }, [_vm._v("Field")]), _c('th', [_vm._v("Required?")]), _c('th', [_vm._v("Relation")])]);

}]

export { render, staticRenderFns }